.photo-spot-preview {
  background-color: white;

  z-index: 110;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  touch-action: none;
  padding: 0;

  .close-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  &__hint {
    width: 100%;
    z-index: 2;
    background-color: white;

    &__title {
      font-family: "Noto Serif TC", serif;
      line-height: 42px;
      text-align: left;
      font-size: 30px;
      color: #966422;
      opacity: 1;
    }

    &__sub-title {
      margin-top: 10px;
      font-size: 16px;
      line-height: 33px;
      color: #525252;

      @include height-smaller-than-iphone-se {
        font-size: 15px;
      }
    }
  }
}

.photo-spot-preview--v {
  .photo-spot-preview__hint {
    padding: 50px 0 0 40px;
    position: absolute;
    bottom: 0;
    left: 0;

    &__sub-title {
      margin-top: 30px;
    }
  }

  .photo-spot-preview__wrapper {
    width: 100vw;
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 3;

    .carousel-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    & > div {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .carousel-image-wrapper {
      height: 100%;
      align-items: flex-end;
      display: flex;

      img {
        height: auto;
        width: 100%;
        overflow: auto;

        vertical-align: bottom;

        position: absolute;
        bottom: 0;
        left: 0;
        top: unset;
        transform: translate(0, 0);
      }
    }
  }
}

.photo-spot-preview--h {
  .photo-spot-preview__hint {
    height: 161px;
    padding: 20px 0 0 40px;
    position: absolute;
    top: 0;
    left: 0;

    &__sub-title {
      margin-top: 10px;
    }
  }

  .photo-spot-preview__wrapper {
    width: 100vw;
    height: calc(100% - 161px);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    .carousel-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    & > div {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .carousel-image-wrapper {
      height: calc(100% - 40px);
      align-items: flex-end;
      display: flex;

      img {
        height: 100%;
        width: auto;
        overflow: auto;

        vertical-align: top;

        position: absolute;
        bottom: 0;
        left: 50%;
        top: unset;
        transform: translate(-50%, 0);
      }
    }
  }
}
