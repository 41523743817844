.map-page {
  .map-header {
    position: absolute;
    top: 0;
    left: 0;
    height: 44px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    display: flex;

    .scanner-icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .info-icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    .floor-switcher {
      position: absolute;
      top: 6px;
      left: 54px;
      height: 32px;
      width: calc(100vw - (44px) * 2 - (10px) * 2);
      background: #f8f8f8 0% 0% no-repeat padding-box;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;

      &__floor {
        flex: 1;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0px;
        color: #a7a5a5;
        font-family: "Noto Serif TC", serif;
        z-index: 3;

        &--selected {
          color: #966422;
        }
      }

      &__select-placeholder {
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 2;

        width: calc((100% / 4) - 6px);
        height: 26px;
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px #00000038;
        border-radius: 5px;
        transition: transform 0.35s;
      }
    }
  }
}
