.pano-controller {
  position: fixed;
  z-index: 2;

  width: 100%;
  height: 45px;
  background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.3), transparent) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;

  &__to-map {
    width: 45px;
    height: 45px;

    & > img {
      margin: 5px 0 0 5px;
    }
  }
  &__toggle-spot {
    width: 45px;
    height: 45px;

    & > img {
      margin-top: 5px;
    }
  }
}

.pano-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 1s;

  .loading-animation {
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes pano-fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pano-fade-out-mask {
  opacity: 0;
  position: fixed;
  z-index: 3;
  height: 100%;
  width: 100%;
  background-color: #000;
  animation: pano-fade-out 0.5s both ease-in-out;
}

.webgl-iframe {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 111;
  border: none;
}

.close-icon-wrapper.ar-iframe {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 112;
}

#react-krpano {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

@import "./tour-spot.scss";
@import "./photo-spot.scss";
@import "./video-player.scss";
@import "./minimap.scss";
