.tour-spot-preview {
  background-color: white;

  z-index: 110;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  touch-action: none;
  padding: 0;

  &__wrapper {
    height: 100vw;
    width: 100vw;
    position: relative;

    .close-icon-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }

    .carousel-pagination {
      position: absolute;
      bottom: 10px;
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.tour-spot-intro {
  background-color: white;

  z-index: 111;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  touch-action: none;
  padding: 30px;

  @include height-smaller-than-iphone-6 {
    padding-top: 10px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    min-height: 42px;

    @include height-smaller-than-iphone-se {
      min-height: 28px;
    }

    & > div {
      font-family: "Noto Serif TC", serif;
      line-height: 42px;
      text-align: left;
      font-size: 30px;
      color: #966422;
      opacity: 1;

      @include height-smaller-than-iphone-se {
        line-height: 27px;
        font-size: 24px;
      }
    }

    & > img {
      width: 42px;
      height: 42px;
    }
  }

  &__description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 29px;
    color: #525252;

    @include height-smaller-than-iphone-6 {
      margin-top: 10px;
      line-height: 31px;
    }

    @include height-smaller-than-iphone-se {
      font-size: 14px;
      margin-top: 10px;
      line-height: 25px;
    }
  }
}

.carousel-image-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  & > img {
    height: 100%;
    width: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.carousel-pagination {
  height: 30px;
  border-radius: 15px;
  background-color: #00000026;
  display: flex;
  padding: 5px;

  &__bullet {
    height: 20px;
    width: 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      height: 10px;
      width: 10px;
      background-color: #00000033;
      border-radius: 50%;
      transition: background-color 0.3s;
    }

    &--enable:before {
      background-color: #ffffff99;
    }
  }
}
