$main-image-width: 414;
$main-image-height: 750;

.home-page {
  background-color: #fafafa;
  width: 100%;
  height: 100%;
  transition: opacity 1.5s;

  &__logo {
    padding: 10px 40px;
    height: 24px;
    width: auto;
    box-sizing: content-box;
  }

  &__outlook {
    margin-top: 4.7vh;
    width: 100vw;
    height: calc(100vw * #{$main-image-width} / #{$main-image-height});
    box-sizing: content-box;

    & > img {
      height: 100%;
      width: auto;
      transition: transform 1.5s;
    }
  }

  &__title {
    margin: 4.15vh 40px 0;
    font-family: "Noto Serif TC", serif;
    line-height: 42px;
    font-size: 28px;
    font-weight: 600;
    color: #966422;
  }

  &__sub-title {
    margin: 3.62vh 40px 0;
    font-size: 15px;
    line-height: 24px;
    color: #454545;
  }

  &__link-button {
    position: absolute;
    bottom: 20px;
    left: 40px;
    width: calc(100% - 80px);
    text-align: center;

    line-height: 45px;
    font-size: 15px;

    background: transparent linear-gradient(99deg, #c19153 0%, #80541b 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 9px;
  }
}

@keyframes home-loading-fade-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.home-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #EDEDED;
  opacity: 1;
  z-index: 10;

  &--fade-out {
    animation: home-loading-fade-out 0.9s both ease-in;
  }

  .loading-animation {
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
