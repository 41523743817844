@import "./common/breakpoints";
@import "./common/base";

html,
body,
#root,
.page {
  margin: 0px;
  padding: 0px;
  font-family: Arial, "文泉驛正黑", "WenQuanYi Zen Hei", "儷黑 Pro", "LiHei Pro", "微軟正黑體", "Microsoft JhengHei",
    "DFKai-SB", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: #000;
  font-size: 16px;
  color: white;
}

html {
  /* For Mobile which width is less than 390px */
  font-size: 16px;
  @media (max-width: 390px) {
    font-size: 14.5px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

@media only screen and (min-device-width: 800px) {
  html {
    overflow: hidden;
  }
}

input {
  font-family: Arial, "文泉驛正黑", "WenQuanYi Zen Hei", "儷黑 Pro", "LiHei Pro", "微軟正黑體", "Microsoft JhengHei",
    "標楷體", "DFKai-SB", sans-serif;
}

.hide {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
}

// Reset slider library's style to enable click/touch events
.slider__slide > div {
  pointer-events: auto !important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

// For demo only
.home-page {
  font-size: 30px;
  line-height: 50px;

  button {
    display: block;
    margin: 50px;
    padding: 10px;
  }
}

@import "./component/map/all";
@import "./component/pano/all";
@import "./component/overview/all";
@import "./component/home/all";

// @import "./component/floor/all";
// @import "./component/krpano/all";
// @import "./component/webgl/index";
