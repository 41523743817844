$thumb-width: 852;
$thumb-height: 630;

.public-facility-modal {
  background-color: white;

  z-index: 111;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  touch-action: none;
  padding: 0;

  button {
    padding: 30px;
    margin: 100px;
  }

  .close-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  &__image {
    width: 100vw;
    height: calc(100vw / 375 * 211);
    transition: opacity 300ms;
  }

  &__content {
    padding: 26px 40px 0;

    &__title {
      height: 83px;
      text-align: left;
      font-family: 'Noto Serif TC', serif;
      font-size: 28px;
      line-height: 42px;
      color: #966422;
    }

    &__description {
      margin-top: 22px;
      text-align: left;
      font: normal normal 15px/21px Noto Sans Myanmar;
      color: #454545;
    }
  }
}
