.minimap-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  overflow: hidden;
  background-color: #919191;
  border-radius: 6px;

  .current-angle {
    top: calc(50% - 2.75px);
    left: calc(50%);
    transform-origin: 5.5px 10.5px;

    position: absolute;
    z-index: 100;
  }

  .minimap-img {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 1s;
  }
}
