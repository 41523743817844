.scene-review-modal {
  position: fixed;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 30px #00000031;
  border-radius: 10px;
  z-index: 101;
  padding: 10px;
  transition: transform 0.5s;

  &__check {
    display: flex;
    justify-content: space-between;

    &__hint {
      margin-left: 4px;
      font-size: 14px;
      line-height: 27px;
      color: #222222;
    }

    &__enter {
      background: #f8f8f8 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 0 10px;
      color: #966422;
      font-size: 12px;
      line-height: 27px;

      img {
        position: relative;
        top: 3px;
        right: 2px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;

    &__spot-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 21px;
      color: #3e3e3e;

      margin-right: 10px;

      .spot-thumb {
        width: 24px;
        height: 24px;
      }

      .spot-count {
        margin-left: 1px;
      }
    }
  }
}
