$header-height: 44px;

.map-page {
  .map-loading-container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 77px);
    width: 100%;
    transition: opacity 1s;

    .loading-animation {
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .map-container {
    position: absolute;
    width: 100%;
    height: calc(100% - #{$header-height});
    top: $header-height;
    touch-action: none;
    user-select: none;
    transition: opacity 1s;

    &__map-images {
      position: absolute;

      & > img {
        height: 100%;
        width: auto;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.3s;
      }
    }

    & > img {
      height: 100%;
      width: auto;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.3s;
    }

    .icon-group-container {
      height: 100%;
      width: 1245px;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.5s;
    }

    .vr-entry-container {
      position: absolute;
      transform: translate(-25px, 12px);
      transform-origin: center;
      z-index: 3;
      transition: transform 0.5s;
      height: 45px;
      width: 44px;

      & > img {
        width: 20px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.5s;
      }

      &--selected {
        z-index: 4;

        & > img {
          transform: translate(-50%, calc(-50% - 14px)) scale(2.2);
        }
      }
    }
  }
}
