.search-marker-icon {
  cursor: pointer;
  width: 44px;
  height: 44px;
  background: transparent linear-gradient(180deg, #3e3e3e 0%, #222222 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #00000080;
  border: 1px solid #454545;
  border-radius: 50%;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.camera-scan-icon {
  cursor: pointer;
  width: 58px;
  height: 58px;
  background: transparent linear-gradient(150deg, #c19153 0%, #80541b 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #00000080;
  border-radius: 50%;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.floor-switch-icon {
  cursor: pointer;
  width: 84px;
  height: 44px;
  background: transparent linear-gradient(156deg, #3e3e3e 0%, #222222 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #00000080;
  border: 1px solid #454545;
  border-radius: 22px;
  position: relative;
  display: flex;

  img {
    padding: 10px 5px 10px 10px;
  }

  div {
    font-size: 15px;
    line-height: 44px;
    position: absolute;
    left: 39px;
  }
}
