.disable-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

@keyframes loader-10-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-10-2 {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

// ref: https://codepen.io/vank0/pen/mARwLg
.loading-animation {
  position: relative;
  animation: loader-10-1 2s infinite linear;
  pointer-events: none;

  &:before,
  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 0.5em solid currentcolor;
    display: block;
    position: absolute;
    border-radius: 100%;
    animation: loader-10-2 2s infinite ease-in-out;
  }

  &:before {
    top: 0;
    left: 50%;
  }

  &:after {
    bottom: 0;
    right: 50%;
    animation-delay: -1s;
  }
}

.close-icon-wrapper {
  height: 60px;
  width: 60px;
  position: relative;

  & > .modal-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.modal-close-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;

  background-color: #0000004d;
  position: relative;

  &:before,
  &:after {
    content: "";
    width: 2px;
    height: 14.4px;
    border-radius: 4px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
