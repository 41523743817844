$thumb-width: 852;
$thumb-height: 630;

.qr-code-modal {
  background-color: white;

  z-index: 111;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  touch-action: none;
  padding: 0;

  button {
    padding: 30px;
    margin: 100px;
  }

  .close-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  .demo-qr-code-image {
    width: 100vw;
    height: calc(100vw * #{$thumb-height} / #{$thumb-width});
  }

  &__content {
    padding: 26px 40px 0;

    & > div,
    & > input {
      min-width: calc(100vw - 80px);
    }

    &__title {
      height: 83px;
      text-align: left;
      font-family: "Noto Serif TC", serif;
      font-size: 28px;
      line-height: 42px;
      color: #966422;
    }

    &__hint {
      margin-top: 22px;
      text-align: left;
      font: normal normal 15px/21px Noto Sans Myanmar;
      color: #454545;
    }

    &__button {
      margin-top: 52px;

      background: transparent linear-gradient(99deg, #c19153 0%, #80541b 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 20px #00000029;
      border-radius: 9px;
      border: none;
      width: 45px;
      line-height: 45px;

      text-align: center;
      font: normal normal 15px/45px Noto Sans Myanmar;
      color: #ffffff;
    }

    @media (max-height: 500px) {
      padding: 8px 40px 0;

      &__hint {
        margin-top: 8px;
      }

      &__button {
        margin-top: 16px;
      }
    }
  }

  .qr-reader-wrapper {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);

    &--onload {
      visibility: hidden;
    }

    & > section {
      position: absolute;
      left: 5%;
      top: 20%;
    }
  }
}
