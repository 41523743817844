.overview-page-top-mask {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 77px;
  background: transparent linear-gradient(180deg, #000000c9 0%, #00000000 100%) 0% 0% no-repeat padding-box;
  transition: opacity 1s;
  transition-delay: 0.2s;

  .close-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
}

$thumb-width: 320;
$thumb-height: 670;

.overview-page {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  // A workaround to disable browser's top bar collapse when scrolling.
  height: calc(100% - 1px);
  width: 100%;
  overflow: auto;
  transition: opacity 1s, transform 0.8s;
  transition-delay: 0.2s;

  .floor-card {
    margin: 20px auto;
    width: calc(100% - 40px);
    background: transparent linear-gradient(135deg, #3e3e3e 0%, #222222 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #000000;
    border-radius: 20px;
    transition: opacity 1s, transform 0.8s;
    transition-delay: 0.2s;

    &__image {
      width: calc(100vw - 40px);
      height: calc((100vw - 40px) * #{$thumb-width} / #{$thumb-height});
      border-radius: 20px 20px 0 0;
    }

    &__spots {
      padding: 10px 19px 22px;
      max-height: 118px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      & > div {
        width: 50%;
        font-size: 13px;
        line-height: 28px;
        color: #fff;
      }
    }

    &__footer {
      border-top: 1px solid #454545;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 10px;

      &__text {
        font-family: "Noto Serif TC", serif;
        font-size: 28px;
        line-height: 40px;
        color: #be8a46;
      }

      &__icon {
        position: relative;
        top: 4px;
      }
    }
  }
}
