.map-page {
  width: 100%;
  height: 100%;
  color: #d6d6d6;
  background-color: #ededed;
  position: relative;
  overflow: hidden;

  .map-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 77px;
    width: 100%;
    background-color: #333333;
    display: flex;

    .camera-scan-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .search-marker-icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
    }

    .floor-switch-icon {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translate(0, -50%);
    }
  }

  .marker-selector {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 200px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px -30px 50px #0000001d;
    z-index: 10;

    &__title {
      font-family: "Noto Serif TC", serif;
      font-size: 17px;
      line-height: 24px;
      color: #966422;
      margin: 10px 20px 8px;
    }

    &__options {
      height: 96px;
      width: 100%;
      display: flex;

      .marker-icon-wrapper {
        cursor: pointer;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: background-color 0.5s;
        background-color: #fff;

        &--enable {
          background-color: #F2F2F2;
        }

        & > div {
          text-align: center;
          line-height: 21px;
          font-size: 12px;
          margin-top: 5px;
          font-weight: lighter;
          color: #3E3E3E;
        }
      }
    }

    &__footer {
      position: relative;
      display: flex;
      justify-content: flex-end;

      &:before {
        content: "";
        border: 1px solid #E8E8E8;
        background-color: #E8E8E8;
        width: calc(100% - 40px);
        position: absolute;
        top: 0;
        left: 20px;
      }

      & > div {
        text-align: center;
        width: 56px;
        height: 42px;
        line-height: 42px;
        font-size: 13px;
        font: Noto Sans Myanmar;
        font-weight: 600;
      }

      &__cancel {
        color: #a0a0a0;
      }

      &__confirm {
        margin-left: 20px;
        margin-right: 15px;
        color: #be8a46;
      }
    }
  }
}

@import "./buttons";
@import "./qr-code-modal";
@import "./public-facility-modal";
@import "./scene-review-modal";
@import "./spot-switch-modal";
@import "./header";
@import "./map-container";
