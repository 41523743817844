$screen-sm-max-width: 640px;
$screen-md-min-width: 641px;
$screen-md-max-width: 992px;
$screen-lg-min-width: 993px;

@mixin screen-media-query($min: false, $max: false) {
  @if ($min == false and $max == false) {
    @warn "mixin screen-media-query(): it should take at least one argument (min or max)";
  }

  @if (($min == false and $max == false) or ($min == 0 and $max == false)) {
    // Return the content directly
    @content;
  } @else if ($min != false and $max != false) {
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if ($max != false) {
    @media screen and (max-width: $max) {
      @content;
    }
  } @else {
    @media screen and (min-width: $min) {
      @content;
    }
  }
}

@mixin screen-small {
  @include screen-media-query(false, $screen-sm-max-width) {
    @content;
  }
}

@mixin screen-middle {
  @include screen-media-query($screen-md-min-width, $screen-md-max-width) {
    @content;
  }
}

@mixin screen-large {
  @include screen-media-query($screen-lg-min-width) {
    @content;
  }
}

@mixin height-smaller-than-iphone-6() {
  @media (max-height: 560px) {
    @content;
  }
}

@mixin height-smaller-than-iphone-se() {
  @media (max-height: 470px) {
    @content;
  }
}
