.spot-switch-modal {
  position: fixed;
  top: 9px;
  left: 9px;
  width: calc(100% - 18px);
  background: #fff;
  box-shadow: 0px 10px 50px #000000bc;
  border-radius: 10px;
  z-index: 10;
  transition: transform 0.5s;

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    &:last-child {
      border-top: 1px solid #F2F2F2;
    }
  }

  &__text {
    color: #5c5c5c;
    text-align: left;
    font: normal normal 600 15px/57px Noto Sans Myanmar;
  }

  &__switcher {
    width: 50px;
    height: 32px;
    padding: 3px 4px;

    background-color: #d6d6d6;
    border-radius: 16px;
    transition: background-color 0.3s;

    &__selector {
      width: 26px;
      height: 26px;
      border-radius: 50%;

      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      transition: transform 0.3s;
    }

    &--active {
      background-color: #be8a46;
      .spot-switch-modal__switcher__selector {
        transform: translateX(16px);
      }
    }
  }
}
