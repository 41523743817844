.video-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;

  &__ios-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: #333333;
    opacity: 0.9;

    &__play-button {
      background-color: #888;
      width: 70vw;
      height: 36vw;
      border-radius: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16vw 0 16vw 39vw;
        border-color: transparent transparent transparent #333;
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .close-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1002;
  }

  &--fit-width > video {
    width: 100vw;
    left: 0;
  }

  &--fit-height > video {
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > video {
    position: absolute;
    width: calc(100vw - 12px);
    left: 6px;
    width: 100vw;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  // A workaround for disable fullscreen button, because krpano doesn't support full screen video
  // This is only available on android (ios doesn't work)
  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
}
